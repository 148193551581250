<template>
  <div>
    <el-card>
      <vab-query-form>
        <vab-query-form-left-panel :span="24">
          <el-button size="large" style="width: 100px" type="primary" @click="goBack()">
            返回
          </el-button>
        </vab-query-form-left-panel>
      </vab-query-form>

      <div class="demo-drawer__content">
        <el-form ref="formRef" :model="form" :rules="rules">
          <el-form-item :label="meetingType == 'EXHIBITION' ? '展商名称' : '机构名称'" prop="exhibitorName">
            <el-input v-model="form.exhibitorName" autocomplete="off" @input="exhNameChange" />
            <div class="box" v-if="enterpriseList.length > 0 && !!form.exhibitorName">
              <template v-for="(item, index) in enterpriseList" :key="index">
                <el-radio-group v-model="form.enterpriseCode">
                  <el-radio :label="item.enterpriseCode" @change="enterpriseChange(item.name)">
                    {{ item.name }}
                  </el-radio>
                </el-radio-group>
              </template>
            </div>
          </el-form-item>
          <el-form-item label="品牌简称" prop="brandName">
            <el-input v-model="form.brandName" autocomplete="off" />
          </el-form-item>
          <el-form-item label="经营品类" prop="businessCategory">
            <el-input v-model="form.businessCategory" autocomplete="off" />
          </el-form-item>
          <el-form-item label="品牌Logo" prop="logo">
            <div class="upLoadImg">
              <wmt-image :src="form.logo" @deleteImg="deleteLogo"></wmt-image>
              <div>
                <el-button @click="upLoadLogo">上传图片</el-button>
                <p>建议上传比例为1：1，200px*200px，小于5MB的图片</p>
              </div>
            </div>
          </el-form-item>
          <el-form-item label="行业分类" prop="industryType">
            <el-select v-model="form.industryType" placeholder="请选择行业分类" :disabled="status != 'new'">
              <el-option v-for="(item, index) in industyTypeList" :key="item.type" :label="item.name" :value="item.type"></el-option>
            </el-select>
          </el-form-item>

          <template v-if="!form.isEnterprise">
            <el-form-item label="负责人姓名">
              <el-input v-model="form.enterpriseUserName" autocomplete="off" />
            </el-form-item>
            <el-form-item label="负责人手机号">
              <el-input v-model="form.enterpriseUserPhone" autocomplete="off" />
            </el-form-item>
          </template>

          <el-form-item label="展位号" v-if="meetingType == 'EXHIBITION'">
            <el-input v-model="form.booth" autocomplete="off" />
          </el-form-item>
          <!-- <el-form-item label="排序">
            <el-input v-model="form.sort" autocomplete="off" type="number" />
          </el-form-item> -->
          <!-- 联系人信息 -->
          <el-form-item label="新增联系人">
            <!-- <el-input v-model="form.contact" autocomplete="off" /> -->
            <el-button type="primary" @click="addContact">添加联系人</el-button>
            <div class="userInfoBox" v-for="(hostInfo, index) in contactInfo" :key="index">
              <el-avatar :size="size" :src="hostInfo.avatar" />
              <div class="detailInfo">
                <div>{{ hostInfo.name }}</div>
                <div>{{ hostInfo.company }} {{ hostInfo.position }}</div>
                <!-- <div>邮箱:{{ hostInfo.email }}</div> -->
              </div>

              <div class="inputBox">
                <el-form-item label="备注">
                  <el-input v-model="hostInfo.dutyDescription" autocomplete="off" maxlength="50" placeholder="请输入备注" @blur="upDateContactInfoFunc(hostInfo)" />
                </el-form-item>
              </div>

              <el-icon @click="deleteUserInfo(index)" style="
                  font-size: 30px;
                  margin-left: 60px;
                  margin-top: 20px;
                  color: red;
                ">
                <Delete />
              </el-icon>
            </div>
          </el-form-item>
          <!-- <el-form-item label="联系电话">
            <el-input v-model="form.contactPhone" autocomplete="off" />
          </el-form-item> -->
          <!-- <el-form-item label="联系邮箱">
            <el-input v-model="form.contactEmail" autocomplete="off" />
          </el-form-item>
          <el-form-item label="联系地址">
            <el-input v-model="form.contactAddress" autocomplete="off" />
          </el-form-item> -->
          <el-form-item label="机构官网">
            <el-input v-model="form.officialWebsite" autocomplete="off" />
          </el-form-item>

          <el-form-item label="详情介绍">
            <Toolbar style="border-bottom: 1px solid #ccc" :editor="editorRef" :defaultConfig="toolbarConfig" :mode="mode" />
            <Editor style="height: 500px; overflow-y: auto" v-model="form.details" :defaultConfig="editorConfig" :mode="mode" @onCreated="handleCreated" />
          </el-form-item>

          <el-form-item align="center">
            <!-- <el-button @click="handleClose">取消</el-button> -->
            <el-button size="large" style="width: 200px" type="primary" @click="submitInfo()">
              提交
            </el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-card>

    <vab-upload ref="vabUploadRef" :limit="1" name="file" :size="2" url="/upload" @addImg="handleAddImg" />
    <vue-cropper ref="vabCropperRef" @imgInfo="logoInfo" :title="'品牌logo'" :option="brandLogoOption" />
    <vue-cropper ref="coverCropperRef" @imgInfo="coverInfo" :title="'展商主K'" :option="mainKOption" />

    <!-- <edit-user ref="editUserRef" @addUserInfo="addUserInfo"></edit-user> -->
    <add-user ref="editUserRef" :code="code" @addUserInfo="addUserInfo"></add-user>
  </div>
</template>

<script>
import {
  defineComponent,
  getCurrentInstance,
  onActivated,
  onMounted,
  reactive,
  toRefs,
  defineAsyncComponent,
  shallowRef,
  onBeforeUnmount,
} from 'vue'
import { upLoadImg } from '@/api/active'
import {
  addBrand,
  getBrandDetail,
  editBrand,
  getAllEnterpriseList,
  getAllEnterpriseDetail,
  generateNotRealCode,
  addBatchContactInfo,
  updateContactInfo,
  queryContactInfoByType,
  queryIndustryTypeExhibitor,
} from '@/api/brand'
import { parseTime } from '@/utils/index'
import VabQuill from '@/extra/VabQuill'
import VabUpload from '@/extra/VabUpload'
import VueCropper from '@/extra/VueCropper'
import { Picture, Delete } from '@element-plus/icons'
import wmtImage from '@/components/wmt-image'
import { Editor, Toolbar } from '@wangeditor/editor-for-vue'
import '@wangeditor/editor/dist/css/style.css'
import { DomEditor } from '@wangeditor/editor'
import { useRoute } from 'vue-router'
// import VueUeditorWrap from 'vue-ueditor-wrap'
export default defineComponent({
  name: 'ArticleEdit',
  components: {
    VabQuill,
    VabUpload,
    VueCropper,
    wmtImage,
    Draggable: defineAsyncComponent(() => import('vuedraggable')),
    Editor,
    Toolbar,
    EditUser: defineAsyncComponent(() => import('./components/editRelated')),
    addUser: defineAsyncComponent(() => import('./components/addContact')),
    // VueUeditorWrap,
  },
  setup(props, { emit }) {
    const { proxy } = getCurrentInstance()

    const route = useRoute()
    // 初始化编辑器
    const editorRef = shallowRef()

    /**
     * @description: logo 自定义校验
     * @return {*}
     */
    const validateLogo = (rule, value, callback) => {
      if (state.form.logo === '') {
        callback(new Error('请上传logo'))
      } else {
        callback()
      }
    }
    /**
     * @description: cover主k 自定义校验
     * @return {*}
     */
    const validateCover = (rule, value, callback) => {
      if (state.form.cover === '') {
        callback(new Error('请上传展商主K'))
      } else {
        callback()
      }
    }

    const validateBannar = (rule, value, callback) => {
      if (state.form.banner.length > 0 && state.form.banner[0] == '') {
        callback(new Error('请上传主页轮播图'))
      } else {
        callback()
      }
    }

    // 自定义职责信息校验
    // const validateDuty = (rule, value, callback) => {
    //   if (state.form.banner.length > 0 && state.form.banner[0] == '') {
    //     callback(new Error('请上传主页轮播图'))
    //   } else {
    //     callback()
    //   }
    // }
    const state = reactive({
      dialogVisible: false,
      articleVisible: false,
      vabCropperRef: null,
      coverCropperRef: null,
      enterpriseFlag: false,
      brandLogoOption: {
        outputSize: 1, // 裁剪生成图片的质量
        outputType: 'jpeg', // 裁剪生成图片的格式 jpeg, png, webp
        info: true, // 裁剪框的大小信息
        canScale: true, // 图片是否允许滚轮缩放
        autoCrop: true, // 是否默认生成截图框
        autoCropWidth: 200, // 默认生成截图框宽度
        autoCropHeight: 200, // 默认生成截图框高度
        fixedBox: true, // 固定截图框大小 不允许改变
        fixed: false, // 是否开启截图框宽高固定比例，这个如果设置为true，截图框会是固定比例缩放的，如果设置为false，则截图框的狂宽高比例就不固定了
        fixedNumber: [1, 1], // 截图框的宽高比例 [ 宽度 , 高度 ]
        canMove: true, // 上传图片是否可以移动
        canMoveBox: true, // 截图框能否拖动
        original: false, // 上传图片按照原始比例渲染
        centerBox: true, // 截图框是否被限制在图片里面
        infoTrue: true, // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
        full: true, // 是否输出原图比例的截图
        enlarge: '1', // 图片根据截图框输出比例倍数
        mode: 'contain', // 图片默认渲染方式 contain , cover, 100px, 100% auto
      },
      mainKOption: {
        outputSize: 1, // 裁剪生成图片的质量
        outputType: 'jpeg', // 裁剪生成图片的格式 jpeg, png, webp
        info: true, // 裁剪框的大小信息
        canScale: true, // 图片是否允许滚轮缩放
        autoCrop: true, // 是否默认生成截图框
        autoCropWidth: 300, // 默认生成截图框宽度
        autoCropHeight: 200, // 默认生成截图框高度
        fixedBox: true, // 固定截图框大小 不允许改变
        fixed: false, // 是否开启截图框宽高固定比例，这个如果设置为true，截图框会是固定比例缩放的，如果设置为false，则截图框的狂宽高比例就不固定了
        fixedNumber: [5, 4], // 截图框的宽高比例 [ 宽度 , 高度 ]
        canMove: true, // 上传图片是否可以移动
        canMoveBox: true, // 截图框能否拖动
        original: false, // 上传图片按照原始比例渲染
        centerBox: true, // 截图框是否被限制在图片里面
        infoTrue: true, // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
        full: true, // 是否输出原图比例的截图
        enlarge: '1', // 图片根据截图框输出比例倍数
        mode: 'contain', // 图片默认渲染方式 contain , cover, 100px, 100% auto
      },
      formRef: null,
      vabUploadRef: null,
      form: {
        content: ``,
        tagIds: [],
        logo: '',
        cover: '',
        banner: [''],
        details: '',
        meetingCode: route.query.meetingCode,
        meetingType: '',
      },
      rules: {
        exhibitorName: [
          { required: true, trigger: 'blur', message: '请输入展商名称' },
        ],
        brandName: [
          { required: true, trigger: 'blur', message: '请输入品牌简称' },
        ],
        businessCategory: [
          { required: true, trigger: 'blur', message: '请输入经营品类' },
        ],
        logo: [{ required: true, trigger: 'change', validator: validateLogo }],
        cover: [
          { required: true, trigger: 'change', validator: validateCover },
        ],
        banner: [
          { required: true, trigger: 'change', validator: validateBannar },
        ],
        enterpriseUserName: [
          { required: true, trigger: 'blur', message: '请输入负责人姓名' },
        ],
        enterpriseUserPhone: [
          { required: true, trigger: 'blur', message: '请输入负责人手机号' },
        ],
      },
      status: 'new',
      enterpriseList: [],
      editUserRef: null,
      contactInfo: [],
      size: 'large',
      notRealCode: '',
      code: '',
      meetingType: '',
      industyTypeList: [
        {
          name: '其他',
          type: '',
        },
      ],
    })

    const upBannar = async (e) => {
      if (state.form.banner.length >= 10) {
        proxy.$baseMessage(
          `当前限制选择 10 个文件，本次选择了10个文件`,
          'error',
          'vab-hey-message-error'
        )
        return false
      }
      let upData = new FormData()
      upData.append('file', e.file)
      const { data } = await upLoadImg(upData)
      state.form.banner.length == 1 && state.form.banner[0] == ''
        ? (state.form.banner[0] = data)
        : state.form.banner.push(data)
      console.log(state.form.banner)
    }

    const handlePreview = (url) => {
      console.log(url)
      state.dialogImageUrl = url
      state.dialogVisible = true
    }

    // 富文本上传图片
    const handleAddImg = async (e) => {
      console.log(state.form.content, '-=======>>>')
      state.form.content += `<img src="${e}" />`
      console.log(state.form.content, '-=======>>>')
    }

    const upDateContactInfoFunc = async (e) => {
      console.log(e)
      try {
        let obj = {}
        obj.dutyDescription = e.dutyDescription
        obj.type = 'EXHIBITOR'
        obj.language = 'CN'
        obj.code =
          state.status == 'new' ? state.notRealCode : state.exhibitorCode
        obj.uid = e.uid
        obj.id = e.contactId
        obj.relationCode =
          state.status == 'new' ? state.notRealCode : state.exhibitorCode
        const { data } = await updateContactInfo(obj)
      } catch (e) {}
    }
    const submitInfo = () => {
      console.log(state.form, state.contactInfo)
      state['formRef'].validate(async (valid) => {
        if (valid) {
          let flag = true
          // 处理职责
          let exhibitorContacts = []
          state.contactInfo.map((res, index) => {
            let obj = {}
            obj.dutyDescription = res.dutyDescription
            obj.uid = res.uid
            obj.sort = index
            obj.type = 'EXHIBITOR'
            obj.language = 'CN'
            obj.code =
              state.status == 'new' ? state.notRealCode : state.exhibitorCode
            obj.relationCode =
              state.status == 'new' ? state.notRealCode : state.exhibitorCode
            exhibitorContacts.push(obj)
          })
          if (exhibitorContacts.length) {
            await addBatchContactInfo(exhibitorContacts)
          }

          if (!flag) {
            return false
          }
          console.log(exhibitorContacts)
          if (state.status == 'new') {
            state.form.bannerList = state.form.banner
            state.form.exhibitorContacts = exhibitorContacts
            // if (!!state.form.enterpriseCode) {
            //   state.form.exhibitorName =
            // }
            state.form.exhibitorCode = state.notRealCode
            let { data, msg } = await addBrand(state.form)
            proxy.$baseMessage(`操作成功`, 'success', 'vab-hey-message-success')
            proxy.$router.replace({
              path: '/exhibitionManage/brand',
              query: {
                meetingCode: state.form.meetingCode,
              },
            })
          } else {
            state.form.bannerList = state.form.banner
            state.form.exhibitorContacts = exhibitorContacts
            let { data, msg } = await editBrand(state.form)
            proxy.$baseMessage(`操作成功`, 'success', 'vab-hey-message-success')
            proxy.$router.replace({
              path: '/exhibitionManage/brand',
              query: {
                meetingCode: state.form.meetingCode,
              },
            })
          }
          // if (state.articleId != 'new') {
          //   let data = await editArticle(state.form)
          //   msg = data.msg
          // } else {
          //   let data = await saveArticle(state.form)
          //   msg = data.msg
          // }
          // proxy.$router.replace({ path: '/contentCenter/contentManage' })
          // proxy.$router.go(-1)
        }
      })
    }

    const upLoadLogo = () => {
      state['vabCropperRef'].dialogVisible = true
    }

    const logoInfo = (logo) => {
      console.log('logo', logo)
      state.form.logo = logo
    }
    const upLoadCover = () => {
      state['coverCropperRef'].dialogVisible = true
    }

    const coverInfo = (logo) => {
      console.log('logo', logo)
      state.form.cover = logo
    }

    const deleteLogo = () => {
      state.form.logo = ''
    }

    const deleteCover = () => {
      state.form.cover = ''
    }

    const deleteBannar = (url) => {
      console.log(url, 'bannar', state.form.banner.indexOf(url))
      let delIndex = state.form.banner.indexOf(url)
      if (state.form.banner.length == 1) {
        state.form.banner = ['']
      } else {
        state.form.banner.splice(delIndex, 1)
      }
    }

    // 设置editor 实例
    const handleCreated = (editor) => {
      editorRef.value = editor // 记录 editor 实例，重要！
    }

    const toolbarConfig = {
      excludeKeys: ['fullScreen', 'group-video'],
    }
    const editorConfig = {
      placeholder: '请输入内容...',
      MENU_CONF: {
        uploadImage: {
          async customUpload(res, insertFn) {
            let upData = new FormData()
            upData.append('file', res)
            const { data } = await upLoadImg(upData)
            insertFn(data, res.name)
          },
        },
      },
    }

    onBeforeUnmount(() => {
      const editor = editorRef.value
      if (editor == null) return
      editor.destroy()
    })

    const fetchData = async () => {
      state.status = route.query.id == 'new' ? 'new' : 'edit'
      state.meetingType = route.query.meetingType
      if (state.status == 'edit') {
        state.exhibitorCode = route.query.id
        const { data } = await getBrandDetail({
          exhibitorCode: state.exhibitorCode,
        })
        data.banner = data.banner ? data.banner.split(',') : []
        data.logo = data.logo ? data.logo : ''
        console.log(data)
        state.form = Object.assign({}, data)
        state.contactInfo = state.form.exhibitorContactVoList
      } else {
        const { data } = await generateNotRealCode({ type: 'EXHIBITOR' })
        console.log(data)
        state.notRealCode = data
      }
      const { data } = await queryIndustryTypeExhibitor()
      console.log(data, '====>>>>')
      state.industyTypeList = data
      state.industyTypeList.push({
        code: 'OTHER',
        name: '其他',
      })
      state.form.industryType = route.query.industryType
    }

    onMounted(() => {
      state.form
      handleCreated()
      fetchData()
    })

    const goBack = () => {
      proxy.$router.go(-1)
    }

    // 展商名称模糊搜索
    const exhNameChange = (e) => {
      console.log(e)
      if (!!e) {
        let queryParams = {
          enterpriseName: e,
          pageNum: 1,
          pageSize: 20,
        }
        state.enterpriseFlag = false
        state.form.contact = ''
        state.form.contactPhone = ''
        getAllEnterpriseList(queryParams).then((res) => {
          console.log(res)
          state.enterpriseList = res.data.list
        })
      } else {
        state.enterpriseFlag = false
        state.form.contact = ''
        state.form.contactPhone = ''
        state.form.enterpriseCode = null
      }
    }

    const enterpriseChange = async (e) => {
      console.log(e)
      state.form.exhibitorName = e
      state.enterpriseFlag = true
      let params = state.enterpriseList.filter((v) => v.name == e)
      console.log(params)
      state.form.contact = params[0].enterpriseSponsorName
      state.form.contactPhone = params[0].enterpriseSponsorPhone
      state.form.contact = params[0].enterpriseSponsorName
      state.form.contactPhone = params[0].enterpriseSponsorPhone

      state.form.contactAddress = params[0].enterpriseStreet
      if (!!params[0].logo) {
        state.form.logo = params[0].logo
      }

      const { data: contactInfo } = await queryContactInfoByType({
        code: params[0].enterpriseCode,
        type: 'ENTERPRISES',
      })
      console.log(contactInfo)
      state.contactInfo = contactInfo
      let exhibitorContacts = []
      state.contactInfo.map((res, index) => {
        let obj = {}
        obj.dutyDescription = res.dutyDescription
        obj.uid = res.uid
        obj.sort = index
        obj.type = 'EXHIBITOR'
        obj.language = 'CN'
        obj.code =
          state.status == 'new' ? state.notRealCode : state.exhibitorCode
        obj.relationCode =
          state.status == 'new' ? state.notRealCode : state.exhibitorCode
        exhibitorContacts.push(obj)
      })
      await addBatchContactInfo(exhibitorContacts)
    }

    const addContact = () => {
      state.code =
        state.status == 'new' ? state.notRealCode : state.exhibitorCode
      console.log(`添加联系人`, state.code)
      state['editUserRef'].showEdit({ code: state.code })
    }

    const addUserInfo = (userInfo) => {
      state.contactInfo = userInfo
      console.log(state.contactInfo)
    }

    const deleteUserInfo = (index) => {
      proxy.$baseConfirm('你确定要删除联系人吗？', null, async () => {
        state.contactInfo.splice(index, 1)
      })
    }
    return {
      ...toRefs(state),
      exhNameChange,
      enterpriseChange,
      upBannar,
      fetchData,
      handleAddImg,
      submitInfo,
      handlePreview,
      upLoadLogo,
      logoInfo,
      coverInfo,
      upLoadCover,
      deleteLogo,
      deleteCover,
      deleteBannar,
      editorRef,
      mode: 'default',
      handleCreated,
      toolbarConfig,
      editorConfig,
      goBack,
      addContact,
      addUserInfo,
      Delete,
      upDateContactInfoFunc,
      deleteUserInfo,
    }
  },
})
</script>

<style lang="scss" scoped>
.upLoadImg {
  display: flex;
  flex-wrap: wrap;
  .draggable {
    display: flex;
    flex-wrap: wrap;
  }
  .image-slot {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 200px;
    height: 200px;
    background: #f5f7fa;
    color: #909399;
    font-size: 30px;
    margin-right: 20px;
  }
  .image-slot .el-icon {
    font-size: 30px;
    color: #909399;
  }
  .hoveBox {
    width: 200px;
    height: 200px;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-content: center;
  }
}

.userInfoBox {
  display: flex;
  justify-content: left;
  background: #67c23a;
  padding: 10px;
  box-sizing: border-box;
  margin-top: 20px;
  .detailInfo {
    margin-left: 20px;
    color: #fff;
    font-size: 16px;
  }
  .inputBox {
    margin-left: 50px;
    margin-top: 20px;
  }
}
</style>
